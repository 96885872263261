<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-state-inspector-json-view">
		<div class="json" v-html="formattedJSON"></div>
	</div>
</template>

<script setup lang="ts">
	import { computed } from 'vue'
	import { jsonCircularReplacer, poorMansHTMLescape } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	
	const props = defineProps({
		json: {
			required: true
		}
	})
	
	// Highlighting
	const createSimpleKeyValueRegEx = (key: string) => ({
		regex: new RegExp('\t"(' + key + ')": (.*?)(,)?\n', 'g'),
		replacement: '\t<span class="obj-$1"><label>"$1"</label>: <span class="value">$2</span>$3</span>\n'
	})
	
	const formatJSON = (json) => {
		if (json === undefined) {
			return 'undefined'
		}
		
		if (typeof(json) == 'string') {
			json = JSON.parse(json)
		}
		
		return poorMansHTMLescape(JSON.stringify(json, jsonCircularReplacer(), 5).replace(/ {5}/g, '\t'))
		
		// We indent with 5 spaces which is unlikely to happen in a value,
		// then replace the 5 spaces with one tab to control indentation via CSS and keep the JS simple
		try {
			let jsonString = poorMansHTMLescape(JSON.stringify(json, jsonCircularReplacer(), 5).replace(/ {5}/g, '\t'))
			let replacements = [
				createSimpleKeyValueRegEx('modelValue'),
				createSimpleKeyValueRegEx('uid'),
				createSimpleKeyValueRegEx('id'),
				createSimpleKeyValueRegEx('tag'),
				createSimpleKeyValueRegEx('_version'),
				{
					regex: /\t"([a-z\d_\-]+)":/gi,
					replacement: '\t<span class="key">"$1"</span>:'
				},
				{
					regex: /(: )"(.*?)"(,)?\n/g,
					replacement: '$1<span class="string">"$2"</span>$3\n'
				},
				{
					regex: /: true(,)?/g,
					replacement: ': <span class="keyword-true">true</span>$1'
				},
				{
					regex: /: false(,)?/g,
					replacement: ': <span class="keyword-false">false</span>$1'
				},
				{
					regex: /: (null|[\d.]+)(,)?/g,
					replacement: ': <span class="keyword-constant">$1</span>$2'
				}
			]
			
			let highlighted = jsonString
			
			for (let r of replacements) {
				highlighted = highlighted.replace(r.regex, r.replacement)
			}
			
			return highlighted
		} catch (e) {
			console.error(e, 'WHOOPS!')
			return json
		}
	}
	
	const formattedJSON = computed(() => formatJSON(props.json))
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-json-view {
		height: 100%;
		
		.json {
			@include selectable;
			height: 100%;
			
			white-space: pre;
			tab-size: 4;
			
			line-height: 1.5;
			font-family: font(monospace);
			
			color: #eee;
			font-size: 1rem;
		}
		
		label {
			font-weight: 400;
		}
		
		span.key,
		span.string {
			color: #e6db74;
		}
		
		span.keyword-constant {
			color: #ae81ff;
		}
		
		span.keyword-true {
			color: color("green");
			font-weight: 600;
		}
		
		span.keyword-false {
			color: color("red");
			font-weight: 600;
		}
		
		span.obj-modelValue {
			color: color("orange");
			font-weight: 600;
			
			background: mix(color("orange"), #000, 20%);
			border-radius: 3px;
		}
		
		span.obj-tag {
			color: color("cyan");
			font-weight: 600;
		}
		
		span.obj-uid, span.obj-id {
			color: color("light-green");
			font-weight: 600;
			
			background: mix(color("light-green"), #000, 20%);
			border-radius: 3px;
		}
		
		span.obj-_version {
			color: color("pink");
			background: mix(color("pink"), #000, 20%);
		}
	}
</style>