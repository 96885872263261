import { installPolyfills } from '@/vendor/polyfills'
installPolyfills()

import { createPinia } from 'pinia'
import { usePiniaLog } from '@/plugins/pinia-log'
import { VERSION as ACQUISIT_UI_VERSION } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

const pinia = createPinia()
pinia.use(usePiniaLog)

Object.defineProperty(window, '__pinia', {
	value: pinia,
	writable: false
})

Object.defineProperty(window, '__ui', {
	value: ACQUISIT_UI_VERSION,
	writable: false
})

// Set up raygun
import { Raygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
Raygun.init(import.meta.env.ACQUISIT_RAYGUN_KEY, import.meta.env.BUILD, import.meta.env.DEV)

// Set up app
import '@Visma-Real-Estate-Solutions/acquisit-ui-vue/dist/style.css'
import uiLoader from '@/plugins/ui-loader'
import { computed, createApp, ref } from 'vue'
import App from './App.vue'

const app = createApp(App)

app.config.errorHandler = (err, vm, info) => {
	Raygun.instance?.send(err as Error, ['vue'], {
		info
	})
}

app.use(uiLoader)
app.use(pinia)

// Set up AcquisitUI
import type { Frontend } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { install } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { loadSVG } from '@/lib/svg'
import { useAcquisitUIBackendAdapter } from '@/lib/backend'
import { createAcquisitUIComponentsStoreAdapter } from '@/stores/components'
import { createAcquisitUIPersonsStoreAdapter } from '@/stores/persons'
import { createAcquisitUIPagesStoreAdapter, useProtocolStore } from '@/stores/protocol'
import { createAcquisitUIProductsStoreAdapter } from '@/stores/products'

const activeLanguage = computed(() => useProtocolStore().language ?? 'nb')

install({
	app,
	env: import.meta.env.DEV ? 'dev' : 'production',
	language: activeLanguage,
	loadSVG,
	
	frontend: ref<Frontend>({
		getRootContainer(): HTMLElement {
			return document.querySelector('#acquisit-page') as HTMLElement ||
			       document.querySelector('#app') as HTMLElement
		},
		
		getRootFooter(): HTMLElement | undefined {
			return undefined
		},
		
		getRootHeader(): HTMLElement | undefined {
			return document.querySelector('.acquisit-app-header') as HTMLElement
		},
	}),
	
	backend: ref(useAcquisitUIBackendAdapter()),
	personsStore: createAcquisitUIPersonsStoreAdapter(),
	pagesStore: createAcquisitUIPagesStoreAdapter(),
	componentsStore: createAcquisitUIComponentsStoreAdapter(),
	productsStore: createAcquisitUIProductsStoreAdapter(),
	dataStore: useProtocolStore().acquisitUIDataStore,
	
	customComponents: [
		'acquisit-signature'
	]
})

// Set up custom field functions
import { registerCustomFieldFunctions } from '@/lib/expressions/field-functions'
registerCustomFieldFunctions()

// Set up custom validators and value handlers
import { setValidator, setValueHandler } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { VALIDATORS } from '@/validators'
import { VALUE_HANDLERS } from '@/value-handlers'

for (let tag in VALIDATORS) {
	if (VALIDATORS.hasOwnProperty(tag)) {
		setValidator(tag, VALIDATORS[tag])
	}
}

for (let tag in VALUE_HANDLERS) {
	if (VALUE_HANDLERS.hasOwnProperty(tag)) {
		setValueHandler(tag, VALUE_HANDLERS[tag])
	}
}

// Set up router
import router from './router'
app.use(router)

// Register global events
import { useUIStore } from '@/stores/ui'
useUIStore().registerResizeListener()

// Polyfills
/**
 * String.prototype.padStart() polyfill
 * https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
 */
if (!String.prototype.padStart) {
	String.prototype.padStart = function padStart(targetLength, padString) {
		targetLength = targetLength>>0; //truncate if number or convert non-number to 0;
		padString = String((typeof padString !== 'undefined' ? padString : ' '));
		if (this.length > targetLength) {
			return String(this);
		}
		else {
			targetLength = targetLength-this.length;
			if (targetLength > padString.length) {
				padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
			}
			return padString.slice(0,targetLength) + String(this);
		}
	};
}

// Go!
app.mount('#app')

import('./vendor/modernizr')